import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Speaker, Check, Eraser, Eye } from 'lucide-react';
import { createWorker } from 'tesseract.js';
import { koreanBeginnerWords } from '../utils/koreanWords';

const KoreanLearningApp = () => {
  // 상태 관리
  const [currentWord, setCurrentWord] = useState(''); // 현재 학습 중인 단어
  const [result, setResult] = useState(null); // 채점 결과 (null: 미채점, true: 정답, false: 오답)
  const [isDrawing, setIsDrawing] = useState(false); // 현재 그리기 중인지 여부
  const [isSpeaking, setIsSpeaking] = useState(false); // 현재 음성 재생 중인지 여부
  const [worker, setWorker] = useState(null); // Tesseract.js OCR 워커
  const [currentImage, setCurrentImage] = useState(''); // 현재 단어에 해당하는 이미지 URL
  const [showResult, setShowResult] = useState(false); // 결과 표시 여부
  const [imageError, setImageError] = useState(false); // 이미지 로딩 오류 여부
  const [isOCRReady, setIsOCRReady] = useState(false); // OCR 시스템 준비 상태
  const [canvasSize, setCanvasSize] = useState(300); // 캔버스 크기

  // 참조 생성
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  // 가이드 단어를 그리는 함수
  const drawGuideWord = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.font = `${canvasSize / 4}px Arial`;
      ctx.fillStyle = 'rgba(200, 200, 200, 0.5)';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(currentWord, canvas.width / 2, canvas.height / 2);
    }
  }, [currentWord, canvasSize]);

  // 랜덤한 단어를 선택하는 함수
  const selectRandomWord = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * koreanBeginnerWords.length);
    const newWord = koreanBeginnerWords[randomIndex];
    setCurrentWord(newWord);
    setResult(null);
    setShowResult(false);
    setCurrentImage(`/images/${newWord}.png`);
    setImageError(false);
  }, []);

  // 윈도우 크기 변경 시 캔버스 크기를 조정하는 함수
  const handleResize = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const newSize = Math.min(containerWidth - 32, 300); // 최대 300px, 32px는 여백
      setCanvasSize(newSize);
    }
  }, []);

  // 캔버스 초기화 함수
  const clearCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setResult(null);
      setShowResult(false);
      drawGuideWord();
    }
  }, [drawGuideWord]);

  // 컴포넌트 마운트 시 실행되는 효과
  useEffect(() => {
    selectRandomWord();
    handleResize();
    window.addEventListener('resize', handleResize);
    
    // OCR 초기화
    const initializeOCR = async () => {
      const newWorker = await createWorker('kor');
      await newWorker.loadLanguage('kor');
      await newWorker.initialize('kor');
      setWorker(newWorker);
      setIsOCRReady(true);
    };
    initializeOCR();

    return () => {
      window.removeEventListener('resize', handleResize);
      if (worker) worker.terminate();
    };
  }, [handleResize, selectRandomWord]);

  // 현재 단어나 캔버스 크기가 변경될 때마다 실행되는 효과
  useEffect(() => {
    if (canvasRef.current) {
      clearCanvas();
    }
  }, [currentWord, canvasSize, clearCanvas]);

  // 음성 재생 함수
  const playSound = useCallback(() => {
    if ('speechSynthesis' in window) {
      if (isSpeaking) {
        window.speechSynthesis.cancel();
        setIsSpeaking(false);
        return;
      }

      const utterance = new SpeechSynthesisUtterance(currentWord);
      utterance.lang = 'ko-KR';
      utterance.onend = () => setIsSpeaking(false);
      utterance.onerror = (event) => {
        console.error('SpeechSynthesis Error', event);
        setIsSpeaking(false);
      };

      setIsSpeaking(true);
      window.speechSynthesis.speak(utterance);
    } else {
      alert(`음성 합성이 지원되지 않습니다. "${currentWord}"를 소리 내어 읽어보세요.`);
    }
  }, [currentWord, isSpeaking]);

  // 그리기 함수
  const draw = useCallback((e) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    let x, y;
    if (e.type.startsWith('touch')) {
      x = (e.touches[0].clientX - rect.left) * scaleX;
      y = (e.touches[0].clientY - rect.top) * scaleY;
    } else {
      x = (e.clientX - rect.left) * scaleX;
      y = (e.clientY - rect.top) * scaleY;
    }

    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.strokeStyle = '#000000';

    ctx.lineTo(x, y);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y);
  }, [isDrawing]);

  // 그리기 시작 함수
  const startDrawing = useCallback((e) => {
    e.preventDefault(); // 기본 동작 방지 (드래그 방지)
    setIsDrawing(true);
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    let x, y;
    if (e.type.startsWith('touch')) {
      x = (e.touches[0].clientX - rect.left) * scaleX;
      y = (e.touches[0].clientY - rect.top) * scaleY;
    } else {
      x = (e.clientX - rect.left) * scaleX;
      y = (e.clientY - rect.top) * scaleY;
    }
    ctx.beginPath();
    ctx.moveTo(x, y);
  }, []);

  // 그리기 종료 함수
  const stopDrawing = useCallback(() => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.beginPath();
    }
  }, []);

  // 채점 함수
  const checkWriting = useCallback(async () => {
    if (!isOCRReady) {
      alert('OCR 시스템이 아직 준비되지 않았습니다. 잠시 후 다시 시도해주세요.');
      return;
    }

    const canvas = canvasRef.current;
    const imageData = canvas.toDataURL('image/png');
    
    try {
      const { data: { text } } = await worker.recognize(imageData);
      const recognizedText = text.trim();
      
      const isCorrect = recognizedText === currentWord;
      setResult(isCorrect);
      setShowResult(true);
      
      if (isCorrect) {
        setTimeout(() => {
          selectRandomWord();
          clearCanvas();
        }, 1500);
      }
    } catch (error) {
      console.error('OCR 인식 중 오류 발생:', error);
      alert('텍스트 인식 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  }, [isOCRReady, worker, currentWord, selectRandomWord, clearCanvas]);

  // 캔버스에 마우스가 올라갔을 때 처리하는 함수
  const handleCanvasHover = () => {
    if (showResult) {
      setShowResult(false);
      clearCanvas();
    }
  };

  // 이미지 로딩 오류 처리 함수
  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-between p-4">
      <div ref={containerRef} className="w-full max-w-md bg-white rounded-3xl shadow-[0_0_20px_rgba(78,204,163,0.25)] overflow-hidden flex flex-col">
        <div className="p-4 flex-grow flex flex-col justify-between">
          {/* 앱 제목 */}
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-emerald-600 mb-4 flex items-center justify-center font-casual">
            한글 까망눈 탈출
            <Eye className="ml-2 text-emerald-600" size={24} />
          </h1>
          
          {/* 현재 학습 단어 표시 */}
          <div className="mb-4 text-center flex items-center justify-center">
            <p className="text-4xl sm:text-5xl font-bold text-mint-dark">{currentWord}</p>
            {/* 음성 재생 버튼 */}
            <button 
              onClick={playSound} 
              className={`ml-4 text-mint-light hover:text-mint-dark ${isSpeaking ? 'animate-pulse' : ''}`}
            >
              <Speaker size={32} />
            </button>
          </div>

          {/* 단어 이미지 표시 (이미지 오류가 없을 때만) */}
          {!imageError && (
            <div className="mb-4 flex justify-center">
              <img 
                src={currentImage} 
                alt={currentWord} 
                className="w-20 h-20 sm:w-24 sm:h-24 object-contain" 
                onError={handleImageError}
              />
            </div>
          )}

          {/* 그리기 캔버스 */}
          <div 
            className="relative mb-4 flex justify-center" 
            onMouseEnter={handleCanvasHover}
            onTouchStart={handleCanvasHover}
          >
            <canvas 
              ref={canvasRef}
              width={canvasSize}
              height={canvasSize}
              onMouseDown={startDrawing}
              onMouseUp={stopDrawing}
              onMouseOut={stopDrawing}
              onMouseMove={draw}
              onTouchStart={startDrawing}
              onTouchEnd={stopDrawing}
              onTouchCancel={stopDrawing}
              onTouchMove={draw}
              className="border-2 border-gray-300 rounded-xl touch-none"
              style={{ touchAction: 'none' }}
            />
            <div className="absolute top-0 left-0 right-0 text-center -mt-3">
              <span className="text-sm font-medium text-gray-500 bg-white px-2">
                따라 쓰기
              </span>
            </div>
            {/* 지우기 버튼 */}
            <button 
              onClick={clearCanvas} 
              className="absolute text-mint-light hover:text-mint-dark"
              style={{
                bottom: `${canvasSize * 0.05}px`,
                right: `${canvasSize * 0.05}px`,
              }}
            >
              <Eraser size={canvasSize * 0.08} />
            </button>
            {/* 결과 표시 (O/X) */}
            {showResult && (
              <div className="absolute inset-0 flex items-center justify-center">
                <span className={`text-9xl ${result ? 'text-green-500' : 'text-red-500'}`}>
                  {result ? '○' : '×'}
                </span>
              </div>
            )}
          </div>

          {/* 채점하기 버튼 */}
          <div className="flex justify-center mt-2">
            <button 
              onClick={checkWriting} 
              className="bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-3 rounded-full text-lg font-semibold flex items-center transition duration-300 ease-in-out"
            >
              채점하기 <Check className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KoreanLearningApp;