import React from 'react';
import KoreanLearningApp from './components/KoreanLearningApp';

// App 컴포넌트: 애플리케이션의 최상위 컴포넌트
function App() {
  return (
    <div className="App">
      <KoreanLearningApp />
    </div>
  );
}

export default App;